<template>
  <div>
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-tooltip-question-outline
      </v-icon> Questionnaries
    </h2>
    <v-col
      class="text-right"
    >
      <v-btn
        color="#FF3700"
        @click="showForm = true"
      >
        Create New
      </v-btn>
    </v-col>
    <v-tabs
      v-model="tab"
      class="questionnaire-tabs"
      color="#FF3700"
      background-color="#ECEFF1"
    >
      <v-tab
        v-for="item in items"
        :key="item.tab"
      >
        {{ item.tab }}
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
      class="my-tabs-background-style"
      touchless
    >
      <v-tab-item
        v-for="item in items"
        :key="item.tab"
      >
        <v-card-text>
          <component :is="item.content" />
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
    <create-questionnaire
      v-if="showForm"
      @closed="showForm = false"
      @success="getAllQuestionnaires"
    />
    <centre-spinner
      :loading="loading"
    />
  </div>
</template>

<script>
  import ActiveQuestionnaires from './ActiveQuestionnaires.vue';
  import DeletedQuestionnaires from './DeletedQuestionnaires.vue';
  import CreateQuestionnaire from 'src/views/dashboard/settings/CreateQuestionnaire.vue';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'Questionnaire',

    components: {
      ActiveQuestionnaires,
      DeletedQuestionnaires,
      'create-questionnaire': CreateQuestionnaire,
      'centre-spinner': spinner,
    },
    data: () => ({
      tab: null,
      items: [
        { tab: 'Active', content: ActiveQuestionnaires },
        { tab: 'Deleted', content: DeletedQuestionnaires },
      ],
      showForm: false,
      loading: false,
    }),
    async mounted () {
      await this.getAllQuestionnaires();
    },
    methods: {
      async getAllQuestionnaires () {
        this.loading = true;
        await this.$store.dispatch('featuresettings/fetchAllQuestionnaire').catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
    },
  };
</script>
<style scoped>
.title-style {
  margin-left: 30px;
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-tabs-background-style {
    background-color: #ECEFF1 !important;
}
.questionnaire-tabs {
  margin-left: 20px;
}
.v-card {
  margin-top: 0px!important;
}
.v-card__text{
  padding: 30px!important;
}
</style>
