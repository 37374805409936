<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-menu
      v-if="isMobile"
      offset-x
      right
    >
      <template #activator="{ on, attrs }">
        <v-icon
          dark
          large
          color="#37474F"
          v-bind="attrs"
          v-on="on"
        >
          mdi-menu
        </v-icon>
      </template>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getDocumentTypes()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-file-document-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Document Types
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getWorkerCategories()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-account-group-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Worker Categories
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getQuestionnaires()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-tooltip-question-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Questionnaire
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-navigation-drawer
      v-if="!isMobile"
      permanent
      color="#ECEFF1"
      expand-on-hover
      absolute
      bottom
      floating
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="nav-title-style">
              <v-list-item-icon>
                <v-icon
                  style="font-size:25px;color:#37474F;font-weight:bold;"
                >
                  mdi-shield-check
                </v-icon>
              </v-list-item-icon>
              Safety
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <br>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getDocumentTypes()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-file-document-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Document Types
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getWorkerCategories()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-account-group-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Worker Categories
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getQuestionnaires()"
          >
            <v-list-item-icon>
              <v-icon style="font-size:25px;color:#37474F;font-weight:bold;">
                mdi-tooltip-question-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              style="font-size:14px;color:#37474F;font-weight:bold;"
            >
              Questionnaire
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <worker-category v-if="workerCategories" />
    <document-types v-if="showDocumentTypes" />
    <questionnaire v-if="showQuestionnaire" />
  </v-container>
</template>

<script>
import WorkerCategory from 'src/views/dashboard/settings/WorkerCategories.vue';
import DocumentTypes from 'src/views/dashboard/settings/DocumentTypes.vue';
import Questionnaire from 'src/views/dashboard/settings/Questionnaire.vue';

  export default {
    name: 'SettingsLandingPage',
    components: {
      'worker-category': WorkerCategory,
      'document-types': DocumentTypes,
      Questionnaire,
    },
    data: () => ({
      selectedItem: -1,
      workerCategories: false,
      showDocumentTypes: true,
      showQuestionnaire: false,
    }),
    computed: {
      isMobile () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return false;
        } else {
          return true;
        }
      },
    },
    mounted () {
      this.selectedItem = 0;
    },
     methods: {
      getWorkerCategories () {
        this.showDocumentTypes = false;
        this.showQuestionnaire = false;
        this.workerCategories = true;
      },
      getDocumentTypes () {
        this.workerCategories = false;
        this.showQuestionnaire = false;
        this.showDocumentTypes = true;
      },
      getQuestionnaires () {
        this.showDocumentTypes = false;
        this.workerCategories = false;
        this.showQuestionnaire = true;
      },
    },
  };
</script>
<style scoped>
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 25px;
}
</style>
