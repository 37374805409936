<template>
  <v-dialog
    v-model="showModal"
    max-width="800"
  >
    <v-card
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon
          class="icon-style"
          left
        >
          mdi-file-document-plus-outline
        </v-icon>Add Document Type
      </h2>
      <br>
      <div class="my-div-style">
        <template v-for="(item, index) in documentTypes">
          <br :key="'i'+ index">
          <v-list-item
            :key="'item'+ index"
            class="list-style"
            color="black"
          >
            <v-checkbox
              v-model="selectedDocumentTypes[item.id]"
              color="#FF3700"
              @change="handleCheckboxChanges(item)"
            />
            <v-list-item-content>
              <v-list-item-title>
                <strong>
                  {{ item.name }}
                </strong>
              </v-list-item-title>
              <font
                style="color:#FF3700"
                class="subtitle-style"
                scrollable
              >
                {{ item.description }}
              </font>
            </v-list-item-content>
            <v-switch
              v-model="mandatoryDocumentTypes[item.id]"
              insert
              color="#FF3700"
              label="Mandatory"
              :disabled="!selectedDocumentTypes[item.id]"
            />
          </v-list-item>
        </template>
      </div>
      <br>
      <v-card-actions>
        <v-btn
          color="red darken-3"
          class="ma-3"
          @click="showModal=false"
        >
          Cancel
        </v-btn><v-spacer />
        <v-btn
          color="#FF3700"
          class="ma-3"
          @click="addDocumentTypes()"
        >
          Add
        </v-btn>
      </v-card-actions>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-dialog>
</template>

<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'AddDocumentTypes',
    components: {
      'centre-spinner': spinner,
    },
    data () {
      return {
        showModal: true,
        loading: false,
        selectedDocumentTypes: {},
        mandatoryDocumentTypes: {},
      };
    },
    computed: {
      documentTypes () {
        return this.$store.getters['featuresettings/getDocumentTypesList'];
      },
      documentTypesChoosed () {
        return Object.keys(this.selectedDocumentTypes).map((id) => ({
          document_id: id,
          mandatory: this.mandatoryDocumentTypes[id] || false,
        }));
      },
      savedDocumentTypes () {
        return this.$store.getters['featuresettings/getSelectedDocumentTypes'];
      },
      filteredDocumentTypes () {
      return this.documentTypes.filter(document => {
        const selectedValue = this.selectedDocumentTypes[document.id];
        return selectedValue === true;
      });
    },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
      this.loading = true;
      await this.fetchDocumentTypes();
      this.loading = false;
    },
    methods: {
        addDocumentTypes () {
          this.$store.dispatch('featuresettings/saveSelectedDocTypes', {
            selectedDocumentType: {
              selected: this.selectedDocumentTypes,
              mandatory: this.mandatoryDocumentTypes,
            },
          });
          const selectedType = this.filteredDocumentTypes;
          const documentTypesChoosed = this.documentTypesChoosed;
          this.$emit('selected-documents', documentTypesChoosed);
          this.$emit('selected-type', selectedType);
          this.showModal = false;
        },
        async fetchDocumentTypes () {
          await this.$store.dispatch('featuresettings/fetchDocumentTypes')
          .then(response => {
            if (Object.keys(this.savedDocumentTypes).length > 0) {
              this.fillDocumentTypesSelected();
            }
          }).catch(() => {
            this.loading = false;
          });
        },
        fillDocumentTypesSelected () {
          this.selectedDocumentTypes = this.savedDocumentTypes.selected;
          this.mandatoryDocumentTypes = this.savedDocumentTypes.mandatory;
        },
        handleCheckboxChanges (item) {
          if (!this.selectedDocumentTypes[item.id]) {
            delete this.mandatoryDocumentTypes[item.id];
            delete this.selectedDocumentTypes[item.id];
          }
       },
    },
  };

</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 20px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.subtitle-style {
   font-size: 13px;
   font-weight: bold;
   margin-top: 5px;
   margin-bottom: 5px;
   overflow-y: hidden;
   overflow-x: hidden;
   padding-right: 5px;
}
.div-style {
    margin-right: auto;
    margin-left: auto;
}
.list-style {
  background-color: #CFD8DC;
  border-radius: 10px;
}
.my-div-style {
    margin-right: 20px;
    margin-left: 20px;
}
</style>
